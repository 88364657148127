<template>
  <div class="footer">
    <div class="footer-social">
      <a href="https://twitter.com/GuildsAndBlades" target="_blank" class="social-link">
        <img class="social-image" :src="`${publicPath}twitter.svg`" alt="Twitter"/>
      </a>
      <a href="https://discord.gg/ARkGUhN73T" target="_blank" class="social-link">
        <img class="social-image" :src="`${publicPath}discord.svg`" alt="Discord"/>
      </a>
    </div>
    <div class="footer-note">©2023 Guilds & Blades. All rights reserved.</div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style.scss';

.footer {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: $spacing5;
  padding: $spacing5 0;
  border-top: 1px solid transparentize($colorContent, 0.85);

  &-social {
    display: flex;
    justify-content: center;

    img {
      max-height: $spacing3;

      &:hover {
        filter: opacity(0.5);
        transition-property: filter;
        transition-duration: 0.5s;
      }
    }
  }

  &-note {
    margin-top: $spacing1;
  }
}
</style>
