import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { format } from 'date-fns'
import Meta from 'vue-meta'
import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: { id: 'UA-210601239-1' },
  includes: [
    { id: 'G-SCQEJ22G25' }
  ]
},
router
)

Vue.use(Meta)
Vue.config.productionTip = false

Vue.filter('date', function (parmval) {
  if (parmval) {
    return format(new Date(parmval), 'yyyy-MM-dd')
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
