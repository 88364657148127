import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutTheGameArticleView from '@/views/article/AboutTheGameArticleView'
import SimilarGamesArticleView from '@/views/article/SimilarGamesArticleView'
import AlienCreaturesArticle from '@/views/article/AlienCreaturesArticle'
import GuidePageView from '@/views/GuidePageView'

const HomeView = () => import('@/views/HomeView')
const SubscribeView = () => import('@/views/SubscribeView')
const SocialView = () => import('@/views/SocialView')
const NewsView = () => import('@/views/NewsView')
const NewsItemView = () => import('@/views/NewsItemView')
const MediaView = () => import('@/views/MediaView')
const SubscribeSuccessView = () => import('@/views/SubscribeSuccessView')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/news',
    name: 'NewsView',
    component: NewsView
  },
  {
    path: '/news/:id',
    name: 'NewsItemView',
    component: NewsItemView
  },
  {
    path: '/media',
    name: 'MediaView',
    component: MediaView
  },
  {
    path: '/social',
    name: 'SocialView',
    component: SocialView
  },
  {
    path: '/subscribe',
    name: 'SubscribeView',
    component: SubscribeView
  },
  {
    path: '/subscribe-success',
    name: 'SubscribeSuccessView',
    component: SubscribeSuccessView
  },
  {
    path: '/article/about-the-game',
    name: 'AboutTheGameArticleView',
    component: AboutTheGameArticleView
  },
  {
    path: '/article/similar-games',
    name: 'SimilarGamesArticleView',
    component: SimilarGamesArticleView
  },
  {
    path: '/article/alien-creatures',
    name: 'AlienCreaturesArticle',
    component: AlienCreaturesArticle
  },
  {
    path: '/guide',
    name: 'GuideView',
    component: GuidePageView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (_to, _from, _savedPosition) {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  },
  routes
})

export default router
