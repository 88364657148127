<template>
  <div class="guide container">
    <div v-if="exception">
      Exception occurred.
    </div>

    <div class="content-box" v-else-if="result">
      <h1>{{ result.fields.title }}</h1>
      <div class="guide__updated">Updated: {{ result.sys.updatedAt | date }}</div>

      <rich-text-renderer :document="result.fields.content" :nodeRenderers="renderNodes()"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

export default {
  name: 'NewsItem',
  components: {
    RichTextRenderer
  },
  metaInfo () {
    return {
      title: `News - ${this.result ? this.result.fields.title : 'Item'}`,
      link: [
        {
          rel: 'canonical',
          href: `https://guildsandblades.com/news/${this.$route.params.id}`
        }
      ]
    }
  },
  data () {
    return {
      lightboxOpen: false,
      lightboxImageIndex: 0
    }
  },
  computed: {
    ...mapState('guide', ['result', 'loading', 'exception']),
    images () {
      if (!this.result || !this.result.fields.screenshots) {
        return []
      }

      return this.result.fields.screenshots.map(
        image => image.fields.file.url + '?fm=jpg&fl=progressive')
    }
  },
  mounted () {
    this.fetch('57RsVlnzPgHvI0qyCx42lF')
  },
  methods: {
    ...mapActions('guide', [
      'fetch'
    ]),
    renderNodes () {
      return {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          // render the EMBEDDED_ASSET as you need
          return (
            <img
              class="guide__image"
              src={`https://${node.data.target.fields.file.url}`}
              alt={node.data.target.fields.description}
            />
          )
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.guide {

  h1 {
    margin-bottom: 0;
  }

  &__updated {
    margin-bottom: $spacing2;
    font-size: small;
  }

  &__image {
    max-width: 100%;

    @media(min-width: $breakpointDesktopLg) {
      max-width: 50%;
    }
  }
}
</style>
