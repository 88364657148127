import Vue from 'vue'
import Vuex from 'vuex'

import ContentService from '@/service/content.service.js'

Vue.use(Vuex)

const CHANGELOG_LIST = 'devlogList'
const CHANGELOG = 'devlog'
const IMAGE = 'image'
const LOADING = 'loading'
const EXCEPTION = 'exception'
const RESULT = 'result'

const devlogListModule = {
  namespaced: true,
  state: {
    [CHANGELOG_LIST]: [],
    [LOADING]: true,
    [EXCEPTION]: false
  },
  mutations: {
    [CHANGELOG_LIST] (state, value) {
      state[CHANGELOG_LIST] = value
    },
    [LOADING] (state, value) {
      state[LOADING] = value
    },
    [EXCEPTION] (state, value) {
      state[EXCEPTION] = value
    }
  },
  actions: {
    async fetchChangelogList ({ commit }) {
      commit(LOADING, true)

      try {
        const result = await ContentService.fetchDevlogs()
        commit(CHANGELOG_LIST, result)
      } catch {
        commit(EXCEPTION, true)
      } finally {
        commit(LOADING, false)
      }
    }
  }
}

const devlogModule = {
  namespaced: true,
  state: {
    [CHANGELOG]: null,
    [LOADING]: true,
    [EXCEPTION]: false
  },
  mutations: {
    [CHANGELOG] (state, value) {
      state[CHANGELOG] = value
    },
    [LOADING] (state, value) {
      state[LOADING] = value
    },
    [EXCEPTION] (state, value) {
      state[EXCEPTION] = value
    }
  },
  actions: {
    async fetchDevlogItem ({ commit }, id) {
      commit(LOADING, true)

      try {
        const result = await ContentService.fetchDevlogItem(id)
        commit(CHANGELOG, result)
      } catch {
        commit(EXCEPTION, true)
      } finally {
        commit(LOADING, false)
      }
    }
  }
}

const imageModule = {
  namespaced: true,
  state: {
    [IMAGE]: null,
    [LOADING]: true,
    [EXCEPTION]: false
  },
  mutations: {
    [IMAGE] (state, value) {
      state[IMAGE] = value
    },
    [LOADING] (state, value) {
      state[LOADING] = value
    },
    [EXCEPTION] (state, value) {
      state[EXCEPTION] = value
    }
  },
  actions: {
    async fetchImage ({ commit }, id) {
      commit(LOADING, true)

      try {
        const result = await ContentService.fetchImage(id)
        commit(IMAGE, result)
      } catch {
        commit(EXCEPTION, true)
      } finally {
        commit(LOADING, false)
      }
    }
  }
}

const mediaModule = {
  namespaced: true,
  state: {
    [RESULT]: null,
    [LOADING]: true,
    [EXCEPTION]: false
  },
  mutations: {
    [RESULT] (state, value) {
      state[RESULT] = value
    },
    [LOADING] (state, value) {
      state[LOADING] = value
    },
    [EXCEPTION] (state, value) {
      state[EXCEPTION] = value
    }
  },
  actions: {
    async fetchPage ({ commit }) {
      commit(LOADING, true)

      try {
        const result = await ContentService.fetchMediaPage()
        commit(RESULT, result)
      } catch {
        commit(EXCEPTION, true)
      } finally {
        commit(LOADING, false)
      }
    }
  }
}

const racesModule = {
  namespaced: true,
  state: {
    [RESULT]: null,
    [LOADING]: true,
    [EXCEPTION]: false
  },
  mutations: {
    [RESULT] (state, value) {
      state[RESULT] = value
    },
    [LOADING] (state, value) {
      state[LOADING] = value
    },
    [EXCEPTION] (state, value) {
      state[EXCEPTION] = value
    }
  },
  actions: {
    async fetchRaces ({ commit }) {
      commit(LOADING, true)

      try {
        const result = await ContentService.fetchRaces()
        commit(RESULT, result)
      } catch {
        commit(EXCEPTION, true)
      } finally {
        commit(LOADING, false)
      }
    }
  }
}

const buildingsModule = {
  namespaced: true,
  state: {
    [RESULT]: null,
    [LOADING]: true,
    [EXCEPTION]: false
  },
  mutations: {
    [RESULT] (state, value) {
      state[RESULT] = value
    },
    [LOADING] (state, value) {
      state[LOADING] = value
    },
    [EXCEPTION] (state, value) {
      state[EXCEPTION] = value
    }
  },
  actions: {
    async fetchBuildings ({ commit }) {
      commit(LOADING, true)

      try {
        const result = await ContentService.fetchBuildings()
        commit(RESULT, result)
      } catch {
        commit(EXCEPTION, true)
      } finally {
        commit(LOADING, false)
      }
    }
  }
}

const guideModule = {
  namespaced: true,
  state: {
    [RESULT]: null,
    [LOADING]: true,
    [EXCEPTION]: false
  },
  mutations: {
    [RESULT] (state, value) {
      state[RESULT] = value
    },
    [LOADING] (state, value) {
      state[LOADING] = value
    },
    [EXCEPTION] (state, value) {
      state[EXCEPTION] = value
    }
  },
  actions: {
    async fetch ({ commit }, id) {
      commit(LOADING, true)

      try {
        const result = await ContentService.fetchGuidePage(id)

        commit(RESULT, result)
      } catch {
        commit(EXCEPTION, true)
      } finally {
        commit(LOADING, false)
      }
    }
  }
}

export default new Vuex.Store({
  modules: {
    devlogList: devlogListModule,
    devlog: devlogModule,
    image: imageModule,
    media: mediaModule,
    races: racesModule,
    buildings: buildingsModule,
    guide: guideModule
  }
})
