<template>
  <div>
    <nav class="nav">
      <div @click="mobileHide = !mobileHide" class="hamburger">
        <svg width="40" height="40" viewBox="0 0 24 24">
          <path d="M5 7H19" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M5 12L19 12" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M5 17L19 17" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>

      <router-link to="/" class="nav-logo-container">
        <img :src="`${publicPath}logo/logo-yellow.svg`" alt="Guilds & Blades" class="nav-logo">
      </router-link>
      <div class="nav-spacing" :class="{'mobile--hide': mobileHide }"></div>
      <router-link :to="{ name: 'HomeView'}" :class="{'mobile--hide': mobileHide }"
                   @click.native="mobileHide = true">Overview
      </router-link>
      <div class="nav-spacing" :class="{'mobile--hide': mobileHide }"></div>
      <router-link :to="{ name: 'GuideView'}" :class="{'mobile--hide': mobileHide }"
                   @click.native="mobileHide = true">Guide
      </router-link>
      <div class="nav-spacing" :class="{'mobile--hide': mobileHide }"></div>
      <router-link :to="{ name: 'MediaView'}" :class="{'mobile--hide': mobileHide }"
                   @click.native="mobileHide = true">Screenshots
      </router-link>
      <div class="nav-spacing" :class="{'mobile--hide': mobileHide }"></div>
      <router-link :to="{ name: 'NewsView'}" :class="{'mobile--hide': mobileHide }"
                   @click.native="mobileHide = true">News
      </router-link>
      <div class="nav-spacing"></div>
      <router-link :to="{ name: 'SocialView'}" :class="{'mobile--hide': mobileHide }"
                   @click.native="mobileHide = true">Social
      </router-link>
      <div class="nav-spacing" :class="{'mobile--hide': mobileHide }"></div>
      <a id="play-link" href="https://game.guildsandblades.com/" class="highlighted-link"
         :class="{'mobile--hide': mobileHide }" @click="mobileHide = true">Play
      </a>
      <div class="nav-spacing" :class="{'mobile--hide': mobileHide }"></div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data () {
    return {
      mobileHide: true,
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.hamburger {
  text-align: right;
  cursor: pointer;

  padding-top: 5px;
  padding-right: 10px;

  @media(min-width: $breakpointDesktopLg) {
    display: none;
  }

  svg {
    stroke: #C4C4C4;

    &:hover {
      stroke: $colorLinkHover;
    }

    &:active {
      stroke: $colorLinkActive;
    }
  }
}

.mobile {
  &--hide {
    display: none;

    @media(min-width: $breakpointDesktopLg) {
      display: flex;
    }
  }
}

nav {
  .nav-logo-container {
    display: none;
    @media(min-width: $breakpointDesktopLg) {
      display: inline;
    }
  }

  .highlighted-link {
    color: $colorLink;

    &:hover {
      color: $colorLinkHover;
    }

    @media(min-width: $breakpointDesktopLg) {
      color: $colorHighlight;

      &:hover {
        color: $colorLinkActive;
      }
    }

    &:active {
      color: $colorLinkHover;
    }
  }

  .highlighted-text {
    color: $colorLinkHover;
    font-weight: bold;
  }
}

</style>
