<template>
  <article>
    <div class="container">
      <div class="content-box">
        <h1>Similar games</h1>

        <section>
          <h2>Supremacy 1914</h2>
          <p>In Supremacy 1914, players take on the role of a nation during World War I. The game is
            a player-vs-player real-time strategy browser game, with players competing against each
            other and against computer-controlled countries. Resource management, province upgrades,
            coalitions and alliances are key gameplay elements, in addition to the conquest aspect.
            The game map can be zoomed in and out, and there is also a province view which allows
            players to see upgrades to that province in a more realistic view than the list format
            that many games of this type have.</p>

          <a href="//www.supremacy1914.com" rel="noopener" target="_blank">Visit Supremacy 1914</a>
        </section>

        <section>
          <h2>Dominus</h2>
          <p>
            Climb the ladder of power by conquering castles and forming alliances with other
            players. In Dominus, armies gather resources slowly over time. Login, give your armies
            orders, then check back in a few hours. Chat with other players to form alliances and
            conquer larger enemies.</p>

          <a href="//dominusgame.net" rel="noopener" target="_blank">Visit Dominus</a>
        </section>

        <section>
          <h2>Neptune's Pride</h2>
          <p>
            Neptune's Pride is a strategy game that takes place in space. In the game, players take
            control of one of several factions vying for control of a star system. The game is
            played
            in real time, or each player taking turns making moves.
          </p>
          <p>
            The game is won by capturing more than a half of star systems stars. Neptune's Pride is
            a challenging game that requires careful planning and
            execution.
          </p>
          <p>
            Players who are new to the game may find it helpful to start with a tutorial. There are
            also many online resources that can help players learn the game. Neptune's Pride is a
            great game for strategy fans.
          </p>
          <a href="//np.ironhelmet.com/#main_menu" rel="noopener" target="_blank">Visit Neptune's
            Pride</a>
        </section>

        <section>
          <h2>Visual Utopia</h2>
          <p>Visual Utopia is a browser-based strategy game that has been around since 1999. It
            offers unique
            game mechanics, 6 races - Human, Elf, Dwarf, Orc, Troll, Halflings. City building, troop
            recruiting, huge map, fights between kingdoms. The game is set in the fictional world of
            Fantasia, and players take on the role of a ruler of one of the six races. They must
            build
            their city, recruit troops, and expand their kingdom. They can also fight other players
            in
            order to conquer their lands.</p>

          <a href="//visual-utopia.com/?openpage=signup.asp%3FsponsorId=89413" rel="noopener"
             target="_blank">Visit Visual Utopia</a>
        </section>

        <section>
          <h2>Travian</h2>
          <p>Travian is a free-to-play, online, real-time strategy game set in the ancient world.
            Players can choose to play as one of three tribes (Romans, Gauls, or Teutons) and
            build
            their own empire, complete with villages, armies, and defenses. The game is
            browser-based, meaning it can be played on any computer with an internet connection.
          </p>
          <p>Travian is a popular game with a large community of players. The game has been around
            for over a decade, and its popularity shows no signs of waning. There are numerous
            websites and forums dedicated to Travian, and the game has even spawned a few
            spin-offs.
          </p>
          <p> If you're looking for a challenging, strategic, and addictive game, Travian is
            definitely worth a try.
          </p>
          <a href="//www.travian.com" rel="noopener" target="_blank">Visit Travian</a>
        </section>

        <section>
          <h2>Ikariam</h2>
          <p>Ikariam is a free browser-based game set in the age of antiquity. Players control a
            small town by managing resources, constructing buildings, and training an army. The
            game has three different versions: the original Greek, the Roman, and the Arabic.
          </p>
          <p>Ikariam was created in 2006 by game developers Stefan Kühn and Toni Tiron. The game
            is published by Gameforge.
          </p>
          <p>Ikariam is a strategy game that requires players to think carefully about how they
            use their resources. The game is set in the age of antiquity, and players must manage
            their resources wisely in order to succeed.
          </p>
          <p>Players must construct buildings in order to produce resources. They must also train
            an army to protect their town.
          </p>
          <p>Ikariam is a challenging game that requires players to think carefully about their
            strategy. The game is great for those who enjoy strategy games and challenging
            themselves.
          </p>
          <a href="//lobby.ikariam.gameforge.com/en_GB/" rel="noopener" target="_blank">Visit
            Ikariam</a>
        </section>
      </div>
    </div>
  </article>

</template>

<script>
export default {
  name: 'SimilarGamesArticleView',
  components: {},
  metaInfo () {
    return {
      title: 'Guilds & Blades - Similar Games'
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

section {
  margin-bottom: $spacing4;
}
</style>
