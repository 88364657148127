const contentful = require('contentful')

const client = contentful.createClient({
  space: 'mgtrjprkfcfc',
  accessToken: 'c2I4V-MlLVDFikt_Xo5p1Qvv8UeI7mtC3DBOS1JmfA4'
})

const fetchDevlogs = async () => {
  return client.getEntries({
    content_type: 'changelog',
    order: '-sys.createdAt'
  })
}

const fetchRaces = async () => {
  return client.getEntries({
    content_type: 'item',
    'fields.type': 'race',
    order: 'fields.order'
  })
}

const fetchBuildings = async () => {
  return client.getEntries({
    content_type: 'item',
    'fields.type': 'building',
    order: 'fields.order'
  })
}

const fetchImage = async (id) => {
  return client.getAsset(id)
}

const fetchDevlogItem = async (id) => {
  return client.getEntry(id)
}

const fetchMediaPage = async () => {
  return client.getEntry('4WRrdpsrq9hlUU28LYB5eU')
}

const fetchGuidePage = async (id) => {
  return client.getEntry(id)
}

export default {
  fetchDevlogs,
  fetchDevlogItem,
  fetchImage,
  fetchMediaPage,
  fetchRaces,
  fetchBuildings,
  fetchGuidePage
}
