<template>
  <article>
    <div class="container">
      <div class="content-box">
        <h1>About the game</h1>
        <div>
          <p>Guilds & Blades is a browser strategy multiplayer game that incorporates city settling,
            building, conquering, troop recruiting, and a dynamic market based on supply and demand.
            In addition, there are spell-casting capabilities, guilds, science, and a dynamic
            hexagonal map. The game is set up so that there are five different races - humans,
            elves, dwarves, orcs and the undead.
          </p>
          <p>The objective of the game is to build up a city, recruit an army, and then conquer
            other cities in order to expand one's empire. In order to be successful, players need to
            make use of the different capabilities of their chosen race. For example, humans are
            good at science, while elves are better at spell-casting. In addition, players need to
            be mindful of the market and make sure that they are producing enough resources to meet
            the demands of their city.
          </p>
          <p>
            Guilds & Blades is a strategy game that requires planning and cooperation. Players need
            to communicate with each other in order to win. The game is fair and balanced, and
            offers a unique experience with different races and guilds.
          </p>

          <div>
            The game has similarities with <router-link :to="{ name: 'SimilarGamesArticleView'}">these games</router-link>, but is completely unique.
          </div>
        </div>
      </div>
    </div>
  </article>

</template>

<script>
export default {
  name: 'AboutTheGameArticleView',
  components: {},
  metaInfo () {
    return {
      title: 'Guilds & Blades - Browser Based Multiplayer Strategy Game'
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.mb1 {
  margin-bottom: $spacing1;
}
</style>
