<template>
  <div id="app">
    <Navigation/>

    <router-view class="router-view"/>

    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Navigation from '@/components/Navigation'

export default {
  components: { Navigation, Footer }
}
</script>

<style lang="scss">
@import '~@/assets/style.scss';
@import '~@/assets/variables.scss';

.router-view {
  @media(min-width: $breakpointDesktopLg) {
    min-height: calc(100vh - 96px - 91px - 91px - 90px - 100px - 100px);
  }
}
</style>
