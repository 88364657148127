<template>
  <article>
    <div class="container">
      <div class="content-box">
        <h1>The Discovery of Alien Life</h1>
        <p>In 2058, a team of scientists made a startling discovery: evidence of alien life on a
          distant planet. These creatures were unlike anything they had ever seen before, and their
          existence raises a whole host of questions about the nature of life in the universe.
        </p>
        <p>
          What do these aliens look like? What is their lifestyle like? What kind of planet do they
          come from?
        </p>
        <p>
          These are just some of the questions that the scientists are now trying to answer. But one
          thing is for sure: the discovery of these aliens has completely changed our understanding
          of the universe, and the possibilities for life within it.
        </p>
        <div class="flex-container">
          <template v-for="(creature, index) in creatures">
            <div class="creature" :key="index">
              <ImageWrapper
                class="creature__image"
                :src="creature.src"
                :desktop-width="470" :ar="[5,2]" :alt="creature.title"/>
              <div class="creature__title"><small>{{ creature.title }}</small></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import ImageWrapper from '@/components/ImageWrapper'

export default {
  name: 'AlienCreaturesArticle',
  components: { ImageWrapper },
  data () {
    return {
      creatures: [
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/nuJNIBT9y68nbzWX4jjZt/a9ae24419c37bd323b87370da4c65429/Acromyrmex_echinatior.png',
          title: 'Acromyrmex echinatior'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/6QBrtWCaRhLPuu5rUtZZAF/5ac57007af8ea9d12b700503214aa19c/Sphex_ichneumoneus.png',
          title: 'Sphex ichneumoneus'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/4FC2SUQ6BGfm95dkhZyDWK/7e2cf8bc070fac34524e82086656017f/Pachycondyla_chinensis.png',
          title: 'Pachycondyla chinensis'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/4jpfheQ7vzohB7gy5oFECS/96b1bba08492c3aeaa1a7ffb908a62e1/Pseudomyrmex_gracilis.png',
          title: 'Pseudomyrmex gracilis'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/4XhspQdfL7VIQSm9mlr3t4/0c6e0609c650631d68582c9a6cc3719a/Solenopsis_invicta.png',
          title: 'Solenopsis invicta'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/4bGPs49ZCxOUZZq1HaPSXU/ac282427ec2392f58d4f0effec5dee8d/Tapinoma_sessile.png',
          title: 'Tapinoma sessile'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/6b7RycvMqooDlihjn4yeJd/21aefd49c67c95c076f1ffcbc4db4931/Atta_cephalotes.png',
          title: 'Atta cephalotes'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/4qkytBHC7gcO2o8XBPh8rD/b1a9eb3f98c11ad0491f4ad59f5f6802/Camponotus_pennsylvanicus.png',
          title: 'Camponotus pennsylvanicus'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/7G9tNQa5q1lZzt2TaoIl4H/a1a493f2ce02cb7cba61857c48275497/Cardiocondyla_emeryi.png',
          title: 'Cardiocondyla emeryi'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/7tQhC6th1UYBGBMx9l4VNy/c725d14dc0ba7ab2c99ffb00490a3660/Crematogaster_scutellaris.png',
          title: 'Crematogaster scutellaris'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/aSOpPNNc1z7Xf4XwiCsed/99f418505fe5f491f1522e609026e3da/Myrmecia_pilosula.png',
          title: 'Myrmecia pilosula'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/5JNU9kbBLyJivovB3y2gHc/fb2f18c151cdb15bab2f52c487df11ea/Formica_rufa.png',
          title: 'Formica rufa'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/4YcjYetDIXow2yTQ877nBq/2fc95b88c9d7e3b44990934c67067aa0/Ficus_benjamina.png',
          title: 'Ficus benjamina'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/ZidnvG4hqxqwX5iPAce6e/957be4f2f424b13eab23c376f37134e1/Nylanderia_fulva.png',
          title: 'Nylanderia fulva'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/4iVpbno9F12GD3xhNDQFAd/de895f6884c1e909907daa8a8e30e508/Harpegnathos_saltator.png',
          title: 'Harpegnathos saltator'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/kxJBT7Hu7FT25CA11apoA/4b23c5361ef97ebf5143d0bebe6e03b7/Linepithema_humile.png',
          title: 'Linepithema humile'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/7MA8dBf9hmXGdoVrTilk1A/06cfba632512513062d7392e9b3242c9/Pheidole_megacephala.png',
          title: 'Pheidole megacephala'
        },
        {
          src: '//images.ctfassets.net/mgtrjprkfcfc/3GszCSXVw0mHjGbewd4RAT/cfde13a165e1c1e4ae159d3f06af53bc/Oecophylla_smaragdina.png',
          title: 'Oecophylla smaragdina'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .creature {
    display: flex;
    flex-direction: column;
    background-color: $colorDarker;

    &:hover {
      background-color: #383838;
      transition-property: background-color;
      transition-duration: 0.5s;
    }

    &__image {
      &:hover {
        filter: contrast(110%);
        transition-property: filter;
        transition-duration: 0.5s;
      }
    }

    &__title {
      padding: 5px;
    }
  }
}
</style>
