<template>
  <img class="img" :src="src + srcSuffix" :alt="alt" :width="width"/>
</template>

<script>
const DESKTOP_BREAKPOINT = 960
const MOBILE_MARGINS = 20

export default {
  name: 'ImageWrapper',
  props: {
    src: {
      type: String,
      required: true
    },
    desktopWidth: {
      type: Number,
      default: null
    },
    maxWidth: {
      type: Number,
      default: null
    },
    ar: {
      type: Array,
      default: null
    },
    alt: {
      type: String,
      default: 'Loading'
    }
  },
  data () {
    return {
      innerWidthProxy: window.innerWidth
    }
  },
  mounted () {
    window.onresize = () => {
      this.innerWidthProxy = window.innerWidth
    }
  },
  computed: {
    srcSuffix () {
      let suffix = '?fm=webp'
      if (this.width) {
        suffix += `&w=${this.width}`
      }
      if (this.height) {
        suffix += `&h=${this.height}`
      }
      return suffix
    },
    width () {
      const desktopWidth = this.desktopWidth ? this.desktopWidth : null
      const mobileWidth = this.innerWidthProxy - MOBILE_MARGINS
      const width = this.innerWidthProxy < DESKTOP_BREAKPOINT ? mobileWidth : desktopWidth

      return (this.maxWidth && width > this.maxWidth) ? this.maxWidth : width
    },
    height () {
      if (!this.ar) {
        return null
      }

      const arYModifier = this.ar[1] / this.ar[0]
      return Math.round(this.width * arYModifier)
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
  max-width: 100%;
}
</style>
